/* eslint-disable @typescript-eslint/quotes */
// import thumb1 from '@/assets/meditation-images/thumb-1.webp';
import thumb1 from '../assets/new-assets/home/meditation-bg.webp';
import thumb2 from '@/assets/meditation-images/thumb-2.webp';
import thumb3 from '@/assets/meditation-images/thumb-3.webp';

export const freeMeditations = [
  {
    id: 398,
    name: `Stop Panic in Exams`,
    description: `Re-energizes your mind, Enhances focus, Reduces stress`,
    thumbnail_sm_url: `https://v3.cdn.level.game/meditations/thumbnails/Stop Panic in exams (Hindi) (2).webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/thumbnails/Stop Panic in exams (Hindi) (2).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/thumbnails/B Stop Panic in exams (Hindi) (1).webp`,
    activity_url: `https://v3.cdn.level.game/meditations/content/Hindi /Ranveer /Hindi Meditations /Hindi Panic in exams.wav`,
    category: `For Work/Study`,
    language: `Hindi`,
    coach_name: `Ranveer`,
    coach_thumbnail: `https://v3.cdn.level.game/coaches/ranveer%20(2).webp`,
    style: { gradient: `linear-gradient(#5c289ee0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 399,
    name: `Stop Panic in Exams`,
    description: `Re-energizes your mind, Enhances focus, Reduces stress`,
    thumbnail_sm_url: `https://v3.cdn.level.game/meditations/thumbnails/Stop Panic in exams (English) (3).webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/thumbnails/Stop Panic in exams (English) (3).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/thumbnails/B Stop Panic in exams (English) (2).webp`,
    activity_url: `https://v3.cdn.level.game/meditations/content/English /Ranveer /English Panic in Exams.wav`,
    category: `For Work/Study`,
    language: `English`,
    coach_name: `Ranveer`,
    coach_thumbnail: `https://v3.cdn.level.game/coaches/ranveer%20(2).webp`,
    style: { gradient: `linear-gradient(#9c3c6ae0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 400,
    name: `Stop Panic in Exams`,
    description: `Re-energizes your mind, Enhances focus, Reduces stress`,
    thumbnail_sm_url: `https://v3.cdn.level.game/meditations/thumbnails/Stop Panic in exams marathi (2).webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/thumbnails/Stop Panic in exams marathi (2).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/thumbnails/B Stop Panic in exams marathi (2).webp`,
    activity_url: `https://v3.cdn.level.game/meditations/content/Marathi /Deepa/Marathi Panic in exams.wav`,
    category: `For Work/Study`,
    language: `Marathi`,
    coach_name: `Deepa`,
    coach_thumbnail: `https://v3.cdn.level.game/coaches/deepa.webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
];

export const manageYourEmotions = [
  {
    id: 1,
    name: `Emotional Pain    `,
    description: `Manage Your Emotions Series (हिन्दी)Learn to manage 10 different emotions with this guided meditation series.Benefits of this meditation:Better control over emotionsThink clearlyTake better decisions`,
    thumbnail_sm_url: `https://v3.cdn.level.game/meditations/1682163850930_Manage%20your%20Emotion%20-%20large%20-%20Coach%20Akash.webp    `,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/1682166272884_Manage%20your%20Emotion%20-%20small%20-%20Coach%20Akash.webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/1682166277475_Manage%20your%20Emotion%20-%20large%20-%20Coach%20Akash.webp`,
    activity_url: `https://v3.cdn.level.game/meditations/1682166282429_MYE+H+DAY+3_02.mp3    `,
    category: `Manage Your Emotions Series (हिन्दी)`,
    language: `Hindi`,
    coach_name: `Akash`,
    coach_thumbnail: `https://v3.cdn.level.game/coaches/Aakash/3-PNG%20(1).png    `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 2,
    name: `Relieve Stress    `,
    description: `Manage Your Emotions Series (हिन्दी)Learn to manage 10 different emotions with this guided meditation series.Benefits of this meditation:Better control over emotionsThink clearlyTake better decisions
    `,
    thumbnail_sm_url: `https://v3.cdn.level.game/meditations/1682163850930_Manage%20your%20Emotion%20-%20large%20-%20Coach%20Akash.webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/1682166212538_Manage%20your%20Emotion%20-%20small%20-%20Coach%20Akash.webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/1682166217052_Manage%20your%20Emotion%20-%20large%20-%20Coach%20Akash.webp`,
    activity_url: `https://v3.cdn.level.game/meditations/1682166282429_MYE+H+DAY+3_02.mp3`,
    category: `Manage Your Emotions Series (हिन्दी)    `,
    language: `Hindi`,
    coach_name: `Akash`,
    coach_thumbnail: `https://v3.cdn.level.game/coaches/Aakash/3-PNG%20(1).png    `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 3,
    name: `Dealing with anxiety`,
    description: `Manage Your Emotions Series (हिन्दी)Learn to manage 10 different emotions with this guided meditation series.Benefits of this meditation:Better control over emotionsThink clearlyTake better decisions`,
    thumbnail_sm_url: `https://v3.cdn.level.game/meditations/1682165296797_Manage%20your%20Emotion%20-%20large%20-%20Coach%20Akash.webp

    `,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/1682166212538_Manage%20your%20Emotion%20-%20small%20-%20Coach%20Akash.webp

    `,
    thumbnail_lg_url: `https://v3.cdn.level.game/level-resized-image/Trending/manage%20emotions.webp

    `,
    activity_url: `https://v3.cdn.level.game/meditations/1682165301866_MYE+H+DAY+1_02.mp3

    `,
    category: `Manage Your Emotions Series (हिन्दी)`,
    language: `Hindi`,
    coach_name: `Akash`,
    coach_thumbnail: `https://v3.cdn.level.game/coaches/Aakash/3-PNG%20(1).png

    `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
];

export const mantraSeries = [
  {
    id: 1,
    name: `Day 1 : Meaning of Mantra`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration`,
    thumbnail_sm_url: `https://v3.cdn.level.game/level-resized-image/Trending/Om%20mantra.webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20small%20-%20Coach%20Om%20ji-1%20(1).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20large%20-%20Coach%20Om%20ji.webp`,
    activity_url: `https://v3.cdn.level.game/meditations/1687853912429_Om+Mantra+Meditation+Day+3_English+QC+GIF+17+Jun+2023.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Om Dhumatkar`,
    coach_thumbnail: `https://v3.cdn.level.game/coaches/Aakash/3-PNG%20(1).png    `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 2,
    name: `Day 2 : Science Behind Mantra `,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration `,
    thumbnail_sm_url: `https://v3.cdn.level.game/meditations/trending/Om%20mantra.webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20small%20-%20Coach%20Om%20ji-1%20(1).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20large%20-%20Coach%20Om%20ji.webp`,
    activity_url: `https://v3.cdn.level.game/meditations/1687853766197_Om+Mantra+Meditation+Day+2_English+QC+GIF+17+Jun+2023.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Om Dhumatkar`,
    coach_thumbnail: `https://v3.cdn.level.game/coaches/Aakash/3-PNG%20(1).png    `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
  {
    id: 2,
    name: `Day 3 : Mantras and Spirituality `,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration `,
    thumbnail_sm_url: `https://v3.cdn.level.game/meditations/trending/Om%20mantra.webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20small%20-%20Coach%20Om%20ji-1%20(1).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20large%20-%20Coach%20Om%20ji.webp`,
    activity_url: `https://v3.cdn.level.game/meditations/1687853766197_Om+Mantra+Meditation+Day+2_English+QC+GIF+17+Jun+2023.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Om Dhumatkar`,
    coach_thumbnail: `https://v3.cdn.level.game/coaches/Aakash/3-PNG%20(1).png    `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
  },
];

export const meditationReccomendation = [
  {
    id: 430,
    name: `Beat Anxious Thoughts`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration`,
    thumbnail_sm_url: `https://d2qt0ksb47ks9g.cloudfront.net/images-data/gpgsg0h7snoquhv03p2iwf.webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20small%20-%20Coach%20Om%20ji-1%20(1).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20large%20-%20Coach%20Om%20ji.webp`,
    activity_url: `https://v3.cdn.level.game/5%2520min%2520clear%2520your%2520anxious%2520mind.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Victoria`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Victoria.webp `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '5mins',
  },
  {
    id: 640,
    name: `Breathwork For Anxiety`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration `,
    thumbnail_sm_url: `https://d2qt0ksb47ks9g.cloudfront.net/images-data/728g0gkc3gqh6f6lsjudu4.webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20small%20-%20Coach%20Om%20ji-1%20(1).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20large%20-%20Coach%20Om%20ji.webp`,
    activity_url: `https://v3.cdn.level.game/MYE%2520Day%252001%2520ALT%2520-Ranveer%2520English%2520With%2520New%2520Intro%2520Outro.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Ranveer`,
    coach_thumbnail: `https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/Coaches/Thumbnails/Ranveer.webp `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '10mins',
  },
];
export const musicReccomendation = [
  {
    id: 369,
    name: `For Better Sleep`,
    thumbnail_sm_url: `https://d2qt0ksb47ks9g.cloudfront.net/images-data/sil9kpaxl61eny3r6lvdf.webp`,
    activity_url:
      'https://v3.cdn.level.game/AquaParadise-%2520Mix%2520M-%2520Sleep%2520aster%25201hr.mp3',
    coach_name: `Level`,
    coach_thumbnail: `https://v3.cdn.level.game/only+logopng.png `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '50mins',
  },
  {
    id: 370,
    name: `Raag for Full Body Relaxation`,
    thumbnail_sm_url: `https://d2qt0ksb47ks9g.cloudfront.net/images-data/tvd53vmz2sfpqxhswihsf.webp`,
    activity_url: 'https://v3.cdn.level.game/raag-pilu-mix-full-vers.mp3',

    coach_name: `Level`,
    coach_thumbnail: `https://v3.cdn.level.game/only+logopng.png `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '45mins',
  },
];

export const workoutReccomendation = [
  {
    id: 1427,
    name: 'Lower Body HIIT',
    thumbnail_sm_url:
      'https://d2qt0ksb47ks9g.cloudfront.net/new-uploads/WORKOUT/WORKOUTS/DAY3-1.webp',
    activity_url:
      'https://v3.cdn.level.game/workouts/Videos/HIIT%20Series/Day%203%20HIIT/Day%203%20-%20Hiit%20-%20Lower%20Body%20Focused%20Line%20Up%20Edit%20For%20Qc%204%20CC%20For%20App.m4v',
    coach_name: `Giftin`,
    coach_thumbnail: `https://v3.cdn.level.game/giftin.jpg`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '31mins',
  },
  {
    id: 1650,
    name: 'Day 4: Kegel Exercise',
    thumbnail_sm_url:
      'https://d2qt0ksb47ks9g.cloudfront.net/Body%20-%202024/PCOD%20Series/New%20Thumbnails/Day%205_%20Kegal%20exercises.webp',
    activity_url:
      'https://d2qt0ksb47ks9g.cloudfront.net/Body%20-%202024/PCOD%20Series/Day%204/Day%205%20Anushka%20Yoga%20Pcod%20Vo%20Match%20%20Qc3%202-Jul(1).mp4',
    coach_name: `Anusha`,
    coach_thumbnail: `https://v3.cdn.level.game/Screenshot+2024-07-11+134408.webp`,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '21mins',
  },
];

export const sleepReccomendation = [
  {
    id: 155,
    name: `Siddhartha Chapter 1`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration`,
    thumbnail_sm_url: `https://d2qt0ksb47ks9g.cloudfront.net/SLEEP%20SECTION/SLEEP%20STORIES/ANCIENT%20WISDOM/siddharta%20chapter%201.webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20small%20-%20Coach%20Om%20ji-1%20(1).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20large%20-%20Coach%20Om%20ji.webp`,
    activity_url: `https://v3.cdn.level.game/Elizabeth%2520_-%2520The%2520Son%2520of%2520A%2520Brahman_rev_2.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Level`,
    coach_thumbnail: `https://v3.cdn.level.game/only+logopng.png `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '20mins',
  },
  {
    id: 640,
    name: `Lord Krishna's Story (हिन्दी)`,
    description: `Om Mantra Series Learn the basics of Mantra Meditation and Om Chanting with this 7-day beginners course. This ancient Indian technique of meditation involves meditating on sacred sounds to relax the mind.  Posture: Sit in a comfortable posture.  When to do it? Early Morning.  Benefits of this series:    Instantly reduces stress and anxiety   Improves overall wellbeing   Enhances focus and concentration `,
    thumbnail_sm_url: `https://d2qt0ksb47ks9g.cloudfront.net/SLEEP%20SECTION/SLEEP%20STORIES/STORIES%20FROM%20PURANAS/lord%20krishnas%20story.webp`,
    thumbnail_md_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20small%20-%20Coach%20Om%20ji-1%20(1).webp`,
    thumbnail_lg_url: `https://v3.cdn.level.game/meditations/Mantra%20Series%20-%20large%20-%20Coach%20Om%20ji.webp`,
    activity_url: `https://v3.cdn.level.game/meditations/1694008960744_Krishna+Janmashatami-Sleep+Stories-Rashmi-Hindi+POST+QC.mp3`,
    category: `Om Mantra Series`,
    language: `English`,
    coach_name: `Level`,
    coach_thumbnail: `https://v3.cdn.level.game/only+logopng.png `,
    style: { gradient: `linear-gradient(#498f9be0, #7159e8e0)` },
    background: thumb1,
    time: '25mins',
  },
];
