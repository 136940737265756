import React from 'react';
import Image from 'next/image';
import { Nav } from '@/components/nav';
import { Meta } from '@/components/meta';
import { DownloadApp } from '@/components/download-app';
import {
  freeMeditations,
  manageYourEmotions,
  mantraSeries,
} from '@/config/free-meditations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';

import searchInformation from '@/assets/meditation-images/search-information.png';
import Blackfriday from '@/components/Blackfridaybar';

// write a function to format seconds to minutes and seconds
export const formatTimeMeditation = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${
    remainingSeconds < 10 ? `0` : ``
  }${remainingSeconds.toFixed(0)}`;
};

export function mediaSessionController(
  title: string,
  artist: string,
  album: string,
  artWork: {
    src: string;
    type: string;
    sizes: `${number}x${number}`;
  },
) {
  if (`mediaSession` in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title,
      artist,
      album,
      artwork: [artWork],
    });

    //navigator.mediaSession.setActionHandler(`play`, () => {
    ///* Code excerpted. */
    //});
    //navigator.mediaSession.setActionHandler(`pause`, () => {
    ///* Code excerpted. */
    //});
    //navigator.mediaSession.setActionHandler(`stop`, () => {
    ///* Code excerpted. */
    //});
    //navigator.mediaSession.setActionHandler(`seekbackward`, () => {
    ///* Code excerpted. */
    //});
    //navigator.mediaSession.setActionHandler(`seekforward`, () => {
    ///* Code excerpted. */
    //});
    //navigator.mediaSession.setActionHandler(`seekto`, () => {
    ///* Code excerpted. */
    //});
    //navigator.mediaSession.setActionHandler(`previoustrack`, () => {
    ///* Code excerpted. */
    //});
    //navigator.mediaSession.setActionHandler(`nexttrack`, () => {
    ///* Code excerpted. */
    //});
    //navigator.mediaSession.setActionHandler(`skipad`, () => {
    ///* Code excerpted. */
    //});
  }
}

export function AudioPlayer(props: {
  id: string;
  url: string;
  onPlay: () => boolean;
  className?: string;
  downloadNowRef: HTMLDivElement | null;
}) {
  const audioRef = React.useRef<HTMLAudioElement>(null);
  const [progress, setProgress] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isBuffering, setIsBuffering] = React.useState(false);

  React.useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return;

    audio.addEventListener(`playing`, () => {
      setIsPlaying(() => true);
      setIsBuffering(() => false);
    });

    audio.addEventListener(`waiting`, () => {
      setIsPlaying(() => false);
      setIsBuffering(() => true);
    });

    audio.addEventListener(`pause`, () => {
      setIsPlaying(() => false);
      setIsBuffering(() => false);
    });

    audio.addEventListener(`play`, () => {
      setIsPlaying(() => true);
      setIsBuffering(() => false);
    });

    audio.addEventListener(`ended`, () => {
      setIsPlaying(() => false);
      setIsBuffering(() => false);
      audio.currentTime = 0;
    });

    const timer = setInterval(() => {
      setProgress(() => audio.currentTime / audio.duration);
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    const audio = audioRef.current;

    const endedListener = () => {
      props.downloadNowRef?.scrollIntoView({ behavior: `smooth` });
    };

    audio?.addEventListener(`ended`, endedListener);
    return () => audio?.removeEventListener(`ended`, endedListener);
  }, [props.downloadNowRef]);

  return (
    <>
      <div className={[`flex`, `flex-row`, `items-center`].join(` `)}>
        <div className="grow ml-4 flex flex-row items-center gap-2">
          <small
            className="text-white min-w-[4ch] text-center"
            style={{ opacity: progress <= 0 ? 0 : 100 }}
          >
            {formatTimeMeditation(audioRef.current?.currentTime || 0)}
          </small>

          <div
            className="grow rounded-full overflow-hidden bg-[#ffffff3b]"
            style={{ opacity: progress <= 0 ? 0 : 100 }}
          >
            <div
              style={{ width: `${progress * 100}%` }}
              className="bg-[#fff] h-[0.3em]"
            />
          </div>
        </div>

        <div
          className={[
            `m-2`,
            `bg-gray-100`,
            `h-[2rem] w-[2rem]`,
            `flex items-center justify-center`,
            `rounded-full`,
            props.className || ``,
          ].join(` `)}
        >
          <audio ref={audioRef} src={props.url} className="hidden" />

          <button
            id={props.id}
            className={[`flex`, `flexr-row`].join(` `)}
            onClick={() => {
              window.document.querySelectorAll(`audio`).forEach((el) => {
                el.pause();
              });

              if (typeof props.onPlay !== `function`)
                throw Error(`onPlay must be a function`);

              if (!props.onPlay()) return;

              isPlaying ? audioRef.current?.pause() : audioRef.current?.play();
            }}
          >
            <FontAwesomeIcon
              icon={isBuffering ? faCircleNotch : isPlaying ? faPause : faPlay}
              width="1em"
              height="1em"
              color="#7256e9"
              fontSize="1rem"
              className={[`p-4`, `cursor-pointer`].join(` `)}
            />
          </button>
        </div>
      </div>
    </>
  );
}

export function EmailCollector(props: {
  isHidden: boolean;
  submit: (e: React.FormEvent<HTMLFormElement>) => void;
}) {
  const [loading, setIsLoading] = React.useState(false);
  const [isHidden, setIsHidden] = React.useState(props.isHidden);

  React.useEffect(() => {
    setIsHidden(props.isHidden);

    if (!props.isHidden) window.document.body.style.overflow = `hidden`;
    else window.document.body.style.overflow = `auto`;
  }, [props.isHidden]);

  return (
    <div
      className={[
        `p-2`,
        `fixed`,
        `inset-0`,
        `flex`,
        `flex-col`,
        `items-center`,
        `justify-center`,
        `bg-[#000000e0]`,
        isHidden ? `invisible` : `visible`,
        isHidden ? `opacity-0` : `opacity-100`,
        `transition-all`,
      ].join(` `)}
    >
      <form
        className={[
          `flex`,
          `flex-col`,
          `gap-4`,
          `bg-[#fff]`,
          `w-full`,
          `max-w-[30rem]`,
          `p-8`,
          `rounded-xl`,
          `drop-shadow-2xl`,
        ].join(` `)}
        onSubmit={async (e) => {
          try {
            setIsLoading(() => true);
            await props.submit(e);
          } catch (err) {
            setIsLoading(() => false);
          }
        }}
      >
        <div className={[`max-w-[15rem]`, `m-auto`, `-mt-[8rem]`].join(` `)}>
          <Image
            alt="Level"
            src={searchInformation.src}
            width={searchInformation.width}
            height={searchInformation.height}
          />
        </div>

        <div
          className={[`text-2xl`, `text-center`, `text-gray-700`, `mb-4`].join(
            ` `,
          )}
        >
          Fill this form to access Now!
        </div>

        <label className={[`flex`, `flex-col`, `gap-2`].join(` `)}>
          <strong>Name</strong>
          <input
            placeholder="Your Name Here"
            type="text"
            name="name"
            required
            className={[
              `px-4`,
              `py-2`,
              `bg-[#FFFAE9]`,
              `border-2`,
              `border-[#FFC417]`,
              `border-dashed`,
              `rounded-lg`,
              `placeholder:text-[#FFC417]`,
            ].join(` `)}
          />
        </label>

        <label className={[`flex`, `flex-col`, `gap-2`].join(` `)}>
          <strong>E-mail</strong>
          <input
            placeholder="Your Email Here"
            type="email"
            name="email"
            required
            className={[
              `px-4`,
              `py-2`,
              `rounded-lg`,
              `bg-[#FFFAE9]`,
              `border-2`,
              `border-[#FFC417]`,
              `border-dashed`,
              `rounded-lg`,
              `placeholder:text-[#FFC417]`,
            ].join(` `)}
          />
        </label>

        <button
          type="submit"
          disabled={loading}
          className={[
            `p-3`,
            `mt-6`,
            `bg-[#7256e9]`,
            `font-bold`,
            `text-white`,
            `rounded-lg`,
          ].join(` `)}
        >
          {loading ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
          ) : (
            `Get Access`
          )}
        </button>

        <div className="text-sm text-gray-400">
          By submitting this form, you agree to receive marketing emails from
          Level.{` `}
          {/* eslint-disable-next-line */}
          <a className="underline" target="_blank" href="/terms-and-conditions">
            Terms and Conditions
          </a>
        </div>
      </form>
    </div>
  );
}

export default function Try() {
  const downloadNowRef = React.useRef<HTMLDivElement>(null);

  const [clickedBtnId, setClickedBtnId] = React.useState(``);
  const [hasUserSubmittedForm, setHasUserSubmittedForm] = React.useState(false);
  const [isEmailCollectorHidden, setIsEmailCollectorHidden] =
    React.useState(true);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const fd = new FormData(e.currentTarget);
    const raw = await fetch(`https://content-panel.lvl.fit/api/collectEmails`, {
      method: `POST`,
      body: JSON.stringify({ name: fd.get(`name`), email: fd.get(`email`) }),
      headers: { 'Content-Type': `application/json` },
    });

    const json = raw.json();
    //console.logjson);

    if (!raw.ok) {
      // MARK: -- handle error and render it on UI
      return;
    }

    setHasUserSubmittedForm(() => true);
    setIsEmailCollectorHidden(() => true);

    const el = window.document.querySelector(
      `#` + clickedBtnId,
    ) as HTMLButtonElement;

    el?.click();
  };

  return (
    <>
      <Nav />
      <Meta
        title="Try some of our Meditations | Level - Supercharge your Mind"
        canonical="https://level.game/meditate"
        isBlog={false}
      />

      {/* Mantra series */}

      <h1
        className={[
          `px-4`,
          `mt-16`,
          `text-3xl`,
          `text-[white]`,
          `font-black`,
          `max-w-6xl`,
          `m-auto`,
          `text-center`,
        ].join(` `)}
      >
        Om Mantra Series 🕉️
      </h1>

      <div
        className={[
          `flex`,
          `md:flex-row`,
          `flex-col`,
          `max-w-7xl`,
          `m-auto`,
          `gap-8`,
          `my-4`,
          `p-6`,
        ].join(` `)}
      >
        {mantraSeries.map((meditation, i) => (
          <div
            key={meditation.id}
            className={[
              `overflow-hidden`,
              `flex`,
              `flex-col`,
              `rounded-xl`,
              `grow`,
              `p-4`,
            ].join(` `)}
            style={{
              backgroundImage: `url('${meditation.background.src}')`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
            }}
          >
            <h2 className="p-4 pb-2 text-3xl text-white font-bold">
              {meditation.name}
            </h2>

            <div className="p-4 pb-2">
              <span
                className={[
                  `px-3 py-1`,
                  `bg-white`,
                  `rounded-full`,
                  `font-bold`,
                ].join(` `)}
              >
                {meditation.language}
              </span>
            </div>

            <div
              className={[
                `prose`,
                `p-4`,
                `pb-2`,
                `font-bold`,
                `text-white`,
              ].join(` `)}
              dangerouslySetInnerHTML={{ __html: meditation.description }}
            />
            <AudioPlayer
              id={`play-btn-${i}`}
              url={meditation.activity_url}
              downloadNowRef={downloadNowRef.current}
              onPlay={() => {
                setClickedBtnId(() => `play-btn-${i}`);

                if (!hasUserSubmittedForm) {
                  setIsEmailCollectorHidden(() => false);
                  return hasUserSubmittedForm;
                }

                mediaSessionController(
                  meditation.name,
                  meditation.coach_name,
                  `Level Meditations`,
                  {
                    src: meditation.background.src,
                    sizes: `512x512`,
                    type: `image/webp`,
                  },
                );

                return hasUserSubmittedForm;
              }}
            />
          </div>
        ))}

        <EmailCollector isHidden={isEmailCollectorHidden} submit={submitForm} />
      </div>

      {/* Manage your emotions */}

      <h2
        className={[
          `px-4`,
          `mt-16`,
          `text-3xl`,
          `font-black`,
          `max-w-6xl`,
          `text-white`,
          `m-auto`,
          `text-center`,
        ].join(` `)}
      >
        Manage your Emotions 😌
      </h2>

      <div
        className={[
          `flex`,
          `md:flex-row`,
          `flex-col`,
          `max-w-7xl`,
          `m-auto`,
          `gap-8`,
          `my-4`,
          `p-6`,
        ].join(` `)}
      >
        {manageYourEmotions.map((meditation, i) => (
          <div
            key={meditation.id}
            className={[
              `overflow-hidden`,
              `flex`,
              `flex-col`,
              `rounded-xl`,
              `grow`,
              `p-4`,
            ].join(` `)}
            style={{
              backgroundImage: `url('${meditation.background.src}')`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
            }}
          >
            <h2 className="p-4 pb-2 text-3xl text-white font-bold">
              {meditation.name}
            </h2>

            <div className="p-4 pb-2">
              <span
                className={[
                  `px-3 py-1`,
                  `bg-white`,
                  `rounded-full`,
                  `font-bold`,
                ].join(` `)}
              >
                {meditation.language}
              </span>
            </div>

            <div
              className={[
                `prose`,
                `p-4`,
                `pb-2`,
                `font-bold`,
                `text-white`,
              ].join(` `)}
              dangerouslySetInnerHTML={{ __html: meditation.description }}
            />

            <AudioPlayer
              id={`play-btn-${i}`}
              url={meditation.activity_url}
              downloadNowRef={downloadNowRef.current}
              onPlay={() => {
                setClickedBtnId(() => `play-btn-${i}`);

                if (!hasUserSubmittedForm) {
                  setIsEmailCollectorHidden(() => false);
                  return hasUserSubmittedForm;
                }

                mediaSessionController(
                  meditation.name,
                  meditation.coach_name,
                  `Level Meditations`,
                  {
                    src: meditation.background.src,
                    sizes: `512x512`,
                    type: `image/webp`,
                  },
                );

                return hasUserSubmittedForm;
              }}
            />
          </div>
        ))}

        <EmailCollector isHidden={isEmailCollectorHidden} submit={submitForm} />
      </div>

      {/* Exam series */}

      <h2
        className={[
          `px-4`,
          `mt-16`,
          `text-3xl`,
          `text-[white]`,
          `font-black`,
          `max-w-6xl`,
          `m-auto`,
          `text-center`,
        ].join(` `)}
      >
        Not Steady at Study? Supercharge Now ⚡
      </h2>

      <div
        className={[
          `flex`,
          `md:flex-row`,
          `flex-col`,
          `max-w-7xl`,
          `m-auto`,
          `gap-8`,
          `my-4`,
          `p-6`,
        ].join(` `)}
      >
        {freeMeditations.map((meditation, i) => (
          <div
            key={meditation.id}
            className={[
              `overflow-hidden`,
              `flex`,
              `flex-col`,
              `rounded-xl`,
              `grow`,
              `p-4`,
            ].join(` `)}
            style={{
              backgroundImage: `url('${meditation.background.src}')`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
            }}
          >
            <h2 className="p-4 pb-2 text-3xl text-white font-bold">
              {meditation.name}
            </h2>

            <div className="p-4 pb-2">
              <span
                className={[
                  `px-3 py-1`,
                  `bg-white`,
                  `rounded-full`,
                  `font-bold`,
                ].join(` `)}
              >
                {meditation.language}
              </span>
            </div>

            <div
              className={[
                `prose`,
                `p-4`,
                `pb-2`,
                `font-bold`,
                `text-white`,
              ].join(` `)}
              dangerouslySetInnerHTML={{ __html: meditation.description }}
            />

            <AudioPlayer
              id={`play-btn-${i}`}
              url={meditation.activity_url}
              downloadNowRef={downloadNowRef.current}
              onPlay={() => {
                setClickedBtnId(() => `play-btn-${i}`);

                if (!hasUserSubmittedForm) {
                  setIsEmailCollectorHidden(() => false);
                  return hasUserSubmittedForm;
                }

                mediaSessionController(
                  meditation.name,
                  meditation.coach_name,
                  `Level Meditations`,
                  {
                    src: meditation.background.src,
                    sizes: `512x512`,
                    type: `image/webp`,
                  },
                );

                return hasUserSubmittedForm;
              }}
            />
          </div>
        ))}

        <EmailCollector isHidden={isEmailCollectorHidden} submit={submitForm} />
      </div>

      {/*  */}
      <p
        className={[
          `prose`,
          `max-w-4xl`,
          `px-4`,
          `mx-auto`,
          `mt-10`,
          `text-center`,
          `text-lg`,
          `text-white`,
        ].join(` `)}
      >
        Get access to more meditations like these, Click on the download button
        below and get ready to supercharge your mind.
      </p>

      <div ref={downloadNowRef}>
        <DownloadApp />
      </div>
    </>
  );
}
